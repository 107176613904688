import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { useState } from "react"
import previous from "../images/previous.svg"
import next from "../images/next.svg"
import { mediaQuery } from "../utils/mediaQuery"

const images = { previous, next }

const arrowStyles = key => css`
  background: no-repeat url(${images[key]}) center;
  border: 0;
  display: block;
  height: 50px;
  width: 50px;
  cursor: pointer;
  height: 100px;
  width: 40px;
  cursor: pointer;
  background-size: 40px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${mediaQuery[1]} {
    background-size: 60px;
    width: 70px;
  }
`

const carouselStyles = margin => css`
  flex: 1;
  overflow: hidden;
  margin: 0 ${margin}px;
`

const containerStyles = css`
  align-items: center;
  display: flex;
`

const transform = (itemsToDisplay, margin, i) => css`
  display: flex;
  transform: translate3d(
    calc(${-i * (100 / itemsToDisplay)}% - ${i * (margin / itemsToDisplay)}px),
    0,
    0
  );
  transition: transform 450ms ease-in;
  > * {
    flex-shrink: 0;
    width: calc(
      ${100 / itemsToDisplay}% -
        ${(margin * (itemsToDisplay - 1)) / itemsToDisplay}px
    );
    :not(:last-of-type) {
      margin-right: ${margin}px;
    }
  }
  max-height: 45em;
`

export const Carousel = ({
  children,
  margin = 30,
  numberOfItems,
  scrollEntirety,
  startingIndex = 0,
  itemsToDisplay = 1,
}) => {
  const [index, setIndex] = useState(startingIndex)
  const scroll = scrollEntirety ? itemsToDisplay : 1

  useEffect(() => {
    if (index > numberOfItems - itemsToDisplay) {
      setIndex(numberOfItems - itemsToDisplay)
    }
  }, [itemsToDisplay, index, numberOfItems])

  return (
    <div css={containerStyles}>
      <button
        aria-label="previous"
        css={arrowStyles("previous")}
        disabled={!index}
        onClick={() => setIndex(index - Math.min(index, scroll))}
      />
      <div css={carouselStyles(margin)}>
        <div css={transform(itemsToDisplay, margin, index)}>{children}</div>
      </div>
      <button
        aria-label="next"
        css={arrowStyles("next")}
        disabled={index === numberOfItems - itemsToDisplay}
        onClick={() =>
          setIndex(
            index + Math.min(numberOfItems - (index + itemsToDisplay), scroll)
          )
        }
      />
    </div>
  )
}
