import { css } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Carousel } from "../../components/carousel"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { ServicesNav } from "../../components/services-nav"
import { mediaQuery } from "../../utils/mediaQuery"
import { GatsbyImage } from "gatsby-plugin-image"

const sectionStyles = theme => css`
  ${mediaQuery[1]} {
    padding-top: ${theme.heights.header};
  }
`

const contentStyles = css`
  padding: 0 30px 20px;

  ${mediaQuery[1]} {
    padding: 0 80px 20px;
  }

  h3 {
    text-align: center;
  }
`

const carouselImageContainerStyles = css`
  position: relative;
  color: white;
`

const textContainerStyles = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 0;
  display: flex;
`

const textWrapperStyles = css`
  width: max-content;
  margin: auto;
`

const carouselTextStyles = css`
  background-color: rgba(0, 0, 0, 0.6);
`

const renderCarouselImages = images => {
  return images.map(image => (
    <div key={image.title} css={carouselImageContainerStyles}>
      <GatsbyImage
        image={image.src.childImageSharp.gatsbyImageData}
        alt={image.title}
      />
      <div css={textContainerStyles}>
        <div css={textWrapperStyles}>
          <h1 css={carouselTextStyles}>{image.title}</h1>
        </div>
      </div>
    </div>
  ))
}

const Photography = () => {
  const data = useStaticQuery(graphql`
    {
      photographyJson {
        images {
          title
          src {
            id
            childImageSharp {
              gatsbyImageData(
                tracedSVGOptions: { color: "#000000", background: "#ffffff" }
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Photography" />
      <section css={sectionStyles}>
        <ServicesNav />
        <div css={contentStyles}>
          <h3>Underwater Photography</h3>
          <p>
            Ever considered an underwater photoshoot ? We shoot product promos
            and business ads for water based enterprises and sports clubs
            although our shoots are not just limited to that. We can also do
            wedding shoots, mother baby shoots, or shots for your personal
            social media accounts.
          </p>
          <p>
            We have access to a pool and scuba equipment, plus all the
            photography and lighting tools necessary to produce captivating and
            original shots. A basic shoot can last around 2 hours including time
            for multiple outfit changes for a varied album for you to keep as a
            personal memory, hang on your wall at home or share with the world.
          </p>
          <p>
            The pool is based in Lausanne, although if you would like to shoot
            closer to home, we can do our best to make it happen! Drop us an
            email and we can discuss your needs in detail.
          </p>
        </div>

        <Carousel
          margin={10}
          itemsToDisplay={2}
          numberOfItems={data.photographyJson.images.length}
        >
          {renderCarouselImages(data.photographyJson.images)}
        </Carousel>
      </section>
    </Layout>
  )
}

export default Photography
